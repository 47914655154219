import React from "react";
import { Spinner } from "flowbite-react";

interface SpinnerProps {
  size?: "sm" | "md" | "lg" | "xl";
  overlay?: boolean; // If true, spinner will cover the whole component
}

// using on button:
// <button>
//    {isLoading ? <LoadingSpinner size="sm" /> : 'Submit'}
// </button>

// using on component:
// return (
//     <div className="relative">
//       <button
//         onClick={fetchData}
//         className="px-4 py-2 text-white bg-green-600 rounded hover:bg-green-700"
//       >
//         Fetch Data
//       </button>
//       {isLoading && <LoadingSpinner overlay />}
//       {!isLoading && <div className="mt-4">Component content goes here.</div>}
//     </div>
//   );
const LoadingSpinner: React.FC<SpinnerProps> = ({
  size = "xl",
  overlay = false,
}) => {
  return (
    <>
      {overlay ? (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-25">
          <Spinner size={size} />
        </div>
      ) : (
        <div className="items-center justify-center 2">
          <Spinner size={size} />
        </div>
      )}
    </>
  );
};

export default LoadingSpinner;
