import { useEffect, useState } from "react";
import {
  Tabs,
  Label,
  TextInput,
  Card,
  Button,
  Checkbox,
  Alert,
} from "flowbite-react";
import useDocumentTitle from "../../utils/customHooks/usePageTitle";
import { Link, useNavigate } from "react-router-dom";
import FlashMessage from "../common/FlashMessage";
import { payForOrderRequest } from "./Order.service";
import { OrderSummaryForm, PaymentResponse } from "./Order.types";
import { useAppSelector } from "../../store";
import LoadingSpinner from "../layout/Spinner/Spinner";
import useQueryParam from "../../utils/customHooks/useQueryParam";
import { AppError, request } from "../../utils/Api.util";
import { SubscriptionPlan, UserRedux } from "../auth/UserSlice";
import { adaptInvoiceToAPI } from "../invoice/services/adaptInvoiceData";

export const OrderSummary = () => {
  const [flashMessage, setFlashMessage] = useState("");
  const [activeTab, setActiveTab] = useState("company");
  const [formFieldErrorTerms, setFormFieldErrorTerms] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] =
    useState<SubscriptionPlan | null>(null); // if component is passed ?plan=X in the url
  // continue paying for the existing payment - maybe a feature for the future
  // const [existingPayment, setExistingPayment] = useState<PaymentResponse | null>(null); // if component is passed ?order=X in the url
  const [isSubmiting, setSubmitting] = useState<boolean>(false);
  const user = useAppSelector<UserRedux>((state) => state.user);
  const [planFromUrl, setPlanFromUrl] = useState<string | null>();
  // continue paying for the existing payment - maybe a feature for the future
  // const [paymentIdFromUrl, setPaymentIdFromUrl] = useState<string | null>();

  const queryParamPlan = useQueryParam("plan");
  // const queryParamOrder = useQueryParam("order");

  const navigate = useNavigate();
  const [formData, setFormData] = useState<OrderSummaryForm>({
    name: "",
    surname: "",
    address_street: "",
    address_postcode: "",
    address_city: "",
    taxNumber: "",
    //@ts-ignore
    companyName: user?.lastInvoice?.seller_line_1 || "",
  });

  const [isTermsAgreeChecked, setTermsAgreeChecked] = useState(false);

  useDocumentTitle("Zamowienie | Instafakt.pl");

  const fetchSubscriptionId = async (): Promise<SubscriptionPlan | void> => {
    if (subscriptionPlan || !planFromUrl) {
      return;
    }

    if (!planFromUrl) {
      setFlashMessage("Nie udało się zebrać danych o wybranej subskrypcji.");
      return;
    }
    const response = await request<{ subscriptionPlan: SubscriptionPlan }>(
      "GET",
      `/subscription-plan/${planFromUrl}`
    );
    if (!response.success) {
      setFlashMessage("Wystąpił błąd z założeniem tej subskrypcji.");
      return;
    }
    return response.responseBody.subscriptionPlan;
  };

  // continuing paying existing payment - maybe a feature for the future
  // const fetchExistingPayment = async (): Promise<SubscriptionPlan | void> => {
  //   if (!paymentIdFromUrl) {
  //     return;
  //   }

  //   const response = await request<{payment: PaymentResponse}>('GET', `/user/:userId/payments/${paymentIdFromUrl}`);
  //   if (!response.success) {
  //     setFlashMessage("Wystąpił błąd z pobraniem płatności.")
  //     return;
  //   }
  //   return response.responseBody.payment
  // }

  useEffect(() => {
    setPlanFromUrl(queryParamPlan);
    fetchSubscriptionId().then((subscriptionPlan) => {
      subscriptionPlan && setSubscriptionPlan(subscriptionPlan);
    });
  }, [planFromUrl]);

  // continuing paying existing payment - maybe a feature for the future
  // useEffect(() => {
  //   setPaymentIdFromUrl( queryParamOrder);
  //   fetchExistingPayment().then(subscriptionPlan => {subscriptionPlan && setSubscriptionPlan(subscriptionPlan)});
  // }, [paymentIdFromUrl])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTermsAgreeCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsAgreeChecked(e.target.checked);
    if (e.target.checked) {
      setFormFieldErrorTerms(""); // Hide error if the checkbox is checked
    }
  };

  const handlePayButtonClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    setSubmitting(true);

    // validate form fields
    let valid = true;

    // Checkbox validation
    if (!isTermsAgreeChecked) {
      setFormFieldErrorTerms("Zaznacz pole z akceptacją regulaminu.");
      valid = false;
    }

    if (!valid || !subscriptionPlan) {
      setSubmitting(false);
      return;
    }

    const paymentPage = await payForOrderRequest({
      ...formData,
      user_id: user.userId,
      plan_id: subscriptionPlan.id,
    });
    setSubmitting(false);
    window.location.href = paymentPage || "/404";
    return;
  };

  return (
    <div className="container mx-auto p-4">
      {flashMessage && <FlashMessage error={new AppError(flashMessage)} />}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Left side: Form Section */}
        <div className="md:col-span-2">
          <Tabs.Group
            aria-label="Order Type"
            style="underline"
            onActiveTabChange={(tabIndex) =>
              setActiveTab(tabIndex === 0 ? "personal" : "company")
            }
          >
            <Tabs.Item title="Firma">
              <form className="space-y-4">
                <div>
                  <Label htmlFor="companyName" value="Nazwa firmy" />
                  <TextInput
                    id="companyName"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    placeholder="Nazwa firmy"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="taxNumber" value="Numer NIP" />
                  <TextInput
                    id="taxNumber"
                    name="taxNumber"
                    value={formData.taxNumber}
                    onChange={handleChange}
                    placeholder="123456789"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="address" value="Adres firmy" />
                  <TextInput
                    id="address_street"
                    name="address_street"
                    value={formData.address_street}
                    onChange={handleChange}
                    placeholder="Ulica"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="address" value="Kod pocztowy" />
                  <TextInput
                    id="address_postcode"
                    name="address_postcode"
                    value={formData.address_postcode}
                    onChange={handleChange}
                    placeholder="00-343"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="address" value="Miasto" />
                  <TextInput
                    id="address_city"
                    name="address_city"
                    value={formData.address_city}
                    onChange={handleChange}
                    placeholder="Miasto"
                    required
                  />
                </div>
              </form>
            </Tabs.Item>

            <Tabs.Item title="Osoba prywatna">
              <form className="space-y-4">
                <div>
                  <Label htmlFor="name" value="Imię" />
                  <TextInput
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Jan"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="surname" value="Nazwisko" />
                  <TextInput
                    id="surname"
                    name="surname"
                    value={formData.surname}
                    onChange={handleChange}
                    placeholder="Doe"
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="address" value="Adres" />
                  <TextInput
                    id="address"
                    name="address"
                    value={formData.address_street}
                    onChange={handleChange}
                    placeholder="123 Main St"
                    required
                  />
                </div>
              </form>
            </Tabs.Item>
          </Tabs.Group>
        </div>

        {/* Right side: Order Summary Section */}
        <div>
          <Card>
            <h2 className="text-lg font-semibold">Zamowienie</h2>
            <div className="space-y-2">
              {!subscriptionPlan ? (
                <LoadingSpinner />
              ) : (
                <>
                  <div>
                    <span className="font-medium">Plan abonamentu:</span>{" "}
                    <strong>Premium Plan</strong>
                  </div>
                  <div>
                    <span className="font-medium">Okres:</span>{" "}
                    {subscriptionPlan.duration_months} mies.
                  </div>
                  <div>
                    <span className="font-medium">Cena:</span>{" "}
                    {Number(subscriptionPlan.price).toFixed(2)} zł
                  </div>
                  <div>
                    <span className="font-medium">VAT:</span> 23 %
                  </div>
                  <div className="font-bold text-xl">
                    Razem: {subscriptionPlan.priceWithVat} zł
                  </div>
                </>
              )}
            </div>
            <div className="flex gap-2 mt-7 mb-5">
              <Checkbox
                id="agree"
                className="cursor-pointer"
                onChange={handleTermsAgreeCheckboxChange}
              />

              <Label className="flex cursor-pointer" htmlFor="agree">
                <p className="text-xs">Akceptuję</p>
                <Link
                  className="text-blue-600 hover:underline dark:text-blue-500 px-1"
                  to="/regulamin"
                  target="new"
                >
                  <p className="text-xs"> regulamin i politykę prywatności</p>
                </Link>
              </Label>
            </div>
            {formFieldErrorTerms && (
              <Alert color="failure" className="mt-2">
                {formFieldErrorTerms}
              </Alert>
            )}

            <Button
              color="blue"
              fullSized={true}
              onClick={handlePayButtonClick}
            >
              {isSubmiting ? <LoadingSpinner size="sm" /> : "Zamawiam"}
            </Button>
          </Card>
        </div>
      </div>
    </div>
  );
};
