import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "./services/RegisterService/Register.types";

export type SubscriptionPlan = {
  id: string;
  name: string;
  price: string;
  priceWithVat: string;
  duration_months: string;
};

export type UserSubscription = {
  endDate: string;
  subscriptionPlanId: number;
  subscriptionPayment?: {
    id: string;
    status: string;
    createdAt: string;
  };
  subscriptionPlan: SubscriptionPlan;
};

export type UserRedux = User & {
  subscription?: {
    endDate: string;
    subscriptionPlanId: number;
    subscriptionPayment?: {
      id: string;
      status: string;
      createdAt: string;
    };
    subscriptionPlan: {
      name: string;
      price: string;
    };
  };
};

const userSlice = createSlice({
  name: "auth",
  initialState: {} as UserRedux,
  reducers: {
    setUser: (_state, action: PayloadAction<UserRedux>) => {
      return action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
