import { useEffect, useState } from 'react';
import { Button, Label, TextInput } from "flowbite-react";
import { useAppDispatch, useAppSelector } from '../../../store';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../UserSlice';
import { login } from '../services/LoginService/Login.service';


export const LoginPage = () => {
    const [userData, setUserData] = useState({ email: '', password: '' });
    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.user);
    console.log(user.token)

    useEffect(() => {
     if (user.token) {
        navigate('/dashboard');
     }   
    }, [user]);

    const handleRegisterButtonClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        const loginResponse = await login({ user: userData })
        if (loginResponse.token) {
            // save user store
            dispatch(setUser(loginResponse));
            navigate('/dashboard')
        }
    }

    return (
        <div className="container mx-auto p-4">
            <section className="bg-white dark:bg-gray-900 px-6 py-4">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Logowanie
                            </h1>

                            <div>
                                <div className="mb-2 mt-6 block">
                                    <Label htmlFor="email" value="Email:" />
                                </div>
                                <TextInput
                                    id="email"
                                    placeholder=""
                                    required
                                    shadow
                                    type="email"
                                    color="blue"
                                    value={userData.email}
                                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                />
                            </div>
                            <div>
                                <div className="mb-2 mt-6 block">
                                    <Label htmlFor="password" value="Hasło" />
                                </div>
                                <TextInput
                                    id="password"
                                    required
                                    shadow
                                    type="password"
                                    color="blue"
                                    value={userData.password}
                                    onChange={(e) => setUserData({ ...userData, password: e.target.value })}

                                />
                            </div>
                            <Button color="blue" fullSized={true} onClick={handleRegisterButtonClick}>Zaloguj się</Button>
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Nie masz konta? <a href="/rejestracja" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Zarejestruj za darmo</a>.
                            </p>

                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}