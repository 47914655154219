import React, { ReactNode } from 'react';
import { Alert } from 'flowbite-react';
import { AppError } from '../../utils/Api.util';
import { Link } from 'react-router-dom';

export const FlashMessage: React.FC<{error: AppError}> = ({error}) => {
  let flashMessage: ReactNode = error?.message ? (<span>{error.message}</span>) : (<span>Wystąpił błąd aplikacji. Skontaktuj się z biurem obsługi klienta.</span>); 
  if (error.no_permission) {
    flashMessage = (
      <span>
        Ta funkcja jest dostępna w wyższym abonamencie.{' '}
        <Link
          to="/cennik" // Replace with your route
          className="text-blue-500 hover:underline"
        >
          Zwiększ abonament
        </Link>
      </span>
    );
  }
  return (
    <Alert color="failure" className="mb-4">
      {flashMessage} 
    </Alert>
  );
};

export default FlashMessage;