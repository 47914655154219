import { Outlet } from "react-router-dom"
import { DashboardNavbar } from "./Navbar/DashboardNavbar"
import { DashboardSidebar } from "./Sidebar/DashboardSidebar"
import { SiteFooter } from "../layout/Footer/SiteFooter"
import { ErrorBoundary } from "react-error-boundary"
import FlashMessage from "../common/FlashMessage"
import { TopBanner } from "./TopBanner/TopBanner"



export const Dashboard = () => {

    return (
        

        <section className="bg-white p-3 sm:p-5" >
        
        
        {/* something is no yes in TopBanner that it causes rerenders - review it before uncommenting */}
            {/* <TopBanner /> */ } 
            
            <DashboardNavbar />

            <aside className="flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 lg:flex transition-width dark:border-gray-700">
                <div className="flex items-start ">
                    <DashboardSidebar />
                </div>
            </aside>

            

            <main className="relative h-full  bg-gray-50 dark:bg-gray-900 lg:ml-64">
                <div className="px-4 pt-6 py-6">
                  <ErrorBoundary FallbackComponent={FlashMessage}>
                    <Outlet/>
                </ErrorBoundary>
                </div>
            </main>
            <SiteFooter />
        </section>

    )
}