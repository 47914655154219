import { Card } from "flowbite-react";
import { useEffect, useState } from "react";
import { request } from "../../../utils/Api.util";
import { useErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";
import { amountFromCents } from "../../../utils/Numbers.util";

type StatsType = {
  this_week_count: number;
  this_week_sum: number;
  this_month_count: number;
  this_month_sum: number;
  this_year_count: number;
  this_year_sum: number;
};

export const DashboardHome = () => {
  const defaultStats = {
    this_week_count: 0,
    this_week_sum: 0,
    this_month_count: 0,
    this_month_sum: 0,
    this_year_count: 0,
    this_year_sum: 0,
  };
  const [stats, setStats] = useState<StatsType>(defaultStats);
  const { showBoundary } = useErrorBoundary();

  const fetchStats = async () => {
    const response = await request<StatsType>(
      "GET",
      `/users/{:userId}/stats`
    ).catch((error) => showBoundary(error));
    setStats(response?.responseBody || defaultStats);
  };
  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div className="p-1 border-gray-200 rounded-lg dark:border-gray-700 mt-4">
      <div className="grid grid-cols-3 gap-4 mb-4">
        <Card className="flex text-center justify-center h-48 rounded bg-gray-50 dark:bg-gray-800">
          <p className="font-medium text-gray-500">Ostatni tydzień:</p>
          <div className="text-5xl font-extrabold text-gray-800 mb-2">
            <span className="font-extrabold">
              {amountFromCents(stats.this_week_sum || 0)}
            </span>{" "}
            zł
          </div>
          <p className="text-sm font-medium text-gray-500">
            {stats.this_week_count || 0} faktur
          </p>
        </Card>
        <Card className="flex text-center justify-center h-48 rounded bg-gray-50 dark:bg-gray-800">
          <p className="font-medium text-gray-500">Ostatni miesiąc:</p>
          <div className="text-5xl font-extrabold text-gray-800 mb-2">
            <span className="font-extrabold">
              {amountFromCents(stats.this_month_sum || 0)}
            </span>{" "}
            zł
          </div>
          <p className="text-sm font-medium text-gray-500">
            {stats.this_month_count || 0} faktur
          </p>
        </Card>
        <Card className="flex text-center justify-center h-48 rounded bg-gray-50 dark:bg-gray-800">
          <p className="font-medium text-gray-500">Ostatni rok:</p>
          <div className="text-5xl font-extrabold text-gray-800 mb-2">
            <span className="font-extrabold">
              {amountFromCents(stats.this_year_sum || 0)}
            </span>{" "}
            zł
          </div>
          <p className="text-sm font-medium text-gray-500">
            <Link to="/dashboard/invoices">
              {stats.this_year_count || 0} faktur
            </Link>
          </p>
        </Card>
      </div>
      {/* <div className="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
               <p className="text-2xl text-gray-400 dark:text-gray-500">
                  <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                     <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                  </svg>
               </p>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
               <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                  <p className="text-2xl text-gray-400 dark:text-gray-500">
                     <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                     </svg>
                  </p>
               </div>
               <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                  <p className="text-2xl text-gray-400 dark:text-gray-500">
                     <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                     </svg>
                  </p>
               </div>
               <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                  <p className="text-2xl text-gray-400 dark:text-gray-500">
                     <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                     </svg>
                  </p>
               </div>
               <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                  <p className="text-2xl text-gray-400 dark:text-gray-500">
                     <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                     </svg>
                  </p>
               </div>
            </div>
            <div className="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
               <p className="text-2xl text-gray-400 dark:text-gray-500">
                  <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                     <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                  </svg>
               </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
               <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                  <p className="text-2xl text-gray-400 dark:text-gray-500">
                     <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                     </svg>
                  </p>
               </div>
               <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                  <p className="text-2xl text-gray-400 dark:text-gray-500">
                     <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                     </svg>
                  </p>
               </div>
               <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                  <p className="text-2xl text-gray-400 dark:text-gray-500">
                     <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                     </svg>
                  </p>
               </div>
               <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
                  <p className="text-2xl text-gray-400 dark:text-gray-500">
                     <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                     </svg>
                  </p>
               </div>
            </div> */}
    </div>
  );
};
