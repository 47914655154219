import { Outlet } from 'react-router-dom';
import { SiteFooter } from '../Footer/SiteFooter';
import { SiteNavbar } from '../Navbar/SiteNavbar';
import { DashboardNavbar } from '../../dashboard/Navbar/DashboardNavbar';
import Cookies from 'js-cookie';

export const Website = () => {

	const isLoggedIn = !!Cookies.get('userToken');

  return (
    <div className="flex flex-col min-h-screen">
      {isLoggedIn ? <DashboardNavbar/> : <SiteNavbar />}
      
      <main className="flex-grow">
        {/* The Outlet component renders the matched route's component */}
        <Outlet />
      </main>
      
      <SiteFooter />
    </div>
  );
};
