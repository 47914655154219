import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Checkbox,
  Label,
  Modal,
  ModalProps,
  TextInput,
} from "flowbite-react";
import {
  logUserIn,
  register,
} from "../services/RegisterService/Register.service";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setUser, UserRedux } from "../UserSlice";
import { adaptInvoiceToAPI } from "../../invoice/services/adaptInvoiceData";
import { request } from "../../../utils/Api.util";
import { validateEmail } from "../../../utils/validators/email.validator";
import { validatePassword } from "../../../utils/validators/password.validator";

export const RegisterModal = (props: ModalProps) => {
  const [userData, setUserData] = useState({ email: "", password: "" });
  const [flashMessage, setFlashMessage] = useState("");
  const [formFieldError, setFormFieldError] = useState<any>({});
  const [isTermsAgreeChecked, setTermsAgreeChecked] = useState(false);

  const invoice = useAppSelector((state) => state.invoice);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const hasFormError = (field: string) => {
    return !!formFieldError[field];
  };

  const handleTermsAgreeCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsAgreeChecked(e.target.checked);
    if (e.target.checked) {
      setFormFieldError({ ...formFieldError, terms: null }); // Hide error if the checkbox is checked
    }
  };

  const handleRegisterButtonClick = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (!isTermsAgreeChecked) {
      setFormFieldError({
        ...formFieldError,
        terms: "Zaznacz pole z akceptacją regulaminu.",
      });
      return;
    }

    const hasUnsavedInvoice = invoice?.number && !invoice?.id;

    const emailInvalidError = validateEmail(userData.email).error;
    if (emailInvalidError) {
      setFlashMessage(emailInvalidError);
      return;
    }

    const emailInvalidPassword = validatePassword(userData.password).error;
    if (emailInvalidPassword) {
      setFlashMessage(emailInvalidPassword);
      return;
    }

    const registerResponse = await request<UserRedux>("POST", "/register", {
      body: {
        user: userData,
        plan: 1,
      },
    });

    if (!registerResponse.success) {
      setFlashMessage(String(registerResponse.responseBody));
      return;
    }

    const registeredUser = registerResponse.responseBody;

    if (registeredUser.token) {
      logUserIn(registeredUser.userId, registeredUser.token);
      dispatch(setUser(registeredUser)); // save user in store

      if (hasUnsavedInvoice) {
        request("POST", `/users/{:userId}/invoices`, {
          body: {
            ...adaptInvoiceToAPI(invoice),
            isNewBuyer: true,
            force: true,
          },
        });
      }
      navigate("/cennik");
    }
  };

  return (
    <Modal show={props.show} onClose={props.onClose} size="md">
      <Modal.Header>Rejestracja</Modal.Header>
      {flashMessage && <Alert color="failure">{flashMessage}</Alert>}

      <Modal.Body>
        <div className="space-y-6 mb-4">
          <p className="text-sm leading-relaxed text-center">
            Załóż konto w Instafakt i zyskaj możliwość zapisywania faktur,
            drukowania, pobierania na dysk, wysyłania mailem i wiele więcej.
          </p>
        </div>

        <div>
          <div className="mb-2 mt-6 block">
            <Label htmlFor="email" value="Email:" />
          </div>
          <TextInput
            id="email"
            placeholder=""
            required
            shadow
            type="email"
            color="blue"
            value={userData.email}
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
          />
        </div>
        <div>
          <div className="mb-2 mt-6 block">
            <Label htmlFor="password" value="Hasło" />
          </div>
          <TextInput
            id="password"
            required
            shadow
            type="password"
            color="blue"
            value={userData.password}
            onChange={(e) =>
              setUserData({ ...userData, password: e.target.value })
            }
          />
        </div>

        <div className="flex items-center gap-2 mt-7 mb-5">
          <Checkbox
            id="agree"
            className="cursor-pointer"
            onChange={handleTermsAgreeCheckboxChange}
          />
          <Label className="flex" htmlFor="agree">
            <p className="text-xs">Akceptuję</p>
            <Link
              className="text-blue-600 hover:underline dark:text-blue-500 px-1"
              to="/regulamin"
              target="new"
            >
              <p className="text-xs"> regulamin i politykę prywatności</p>
            </Link>
          </Label>
        </div>
        {hasFormError("terms") && (
          <Alert color="failure" className="mb-4">
            {formFieldError.terms}
          </Alert>
        )}

        <Button
          color="blue"
          fullSized={true}
          onClick={handleRegisterButtonClick}
        >
          Załóż konto
        </Button>
      </Modal.Body>
    </Modal>
  );
};
