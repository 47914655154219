import { Badge, Button, Card } from "flowbite-react";
import { useAppSelector } from "../../../store";
import { addDaysToDate, compareDates, niceDate, todayDate } from "../../../utils/Date.util";
import { Link, useNavigate } from "react-router-dom";

export const AccountSettings = () => {

    const { subscription } = useAppSelector(state => state.user);

    const navigate = useNavigate();

    const handleProlong = () => {
        // Logic to prolong the plan (e.g., API call)
         navigate("/cennik")
    };
    // Check if the plan is active
    const setPlanInfoText = () => {
      const hasPlan = !!subscription?.subscriptionPlanId;
      const isOnPaidPlan = hasPlan && subscription.subscriptionPlanId != 1;
      const isPlanBeforeDeadline = compareDates(todayDate(), niceDate(subscription?.endDate || addDaysToDate(todayDate(), -1)));
      // const isActive = compareDates(todayDate(), niceDate(subscription?.endDate || todayDate()));
      const isAwaitingPaymentForPaidPlan = isOnPaidPlan && isPlanBeforeDeadline && subscription?.subscriptionPayment?.status != 'paid';
      if (!hasPlan) {
        return <>
          <Badge color="failure">Brak abonamentu.</Badge>
          <Button className="mt-2" onClick={handleProlong}>Wybierz swój abonament</Button>
        </>;
      }

      if (!isPlanBeforeDeadline) {
        return <>
          <Badge color="failure">Wygasły.</Badge>
          <span>Wygasł w dniu: {niceDate(subscription?.endDate || todayDate())}</span>
          <Button className="mt-2" onClick={handleProlong}>Kup abonament</Button>
        </>;
      }
      if (!isOnPaidPlan) {
        return <>
          <Badge color="success">Aktywny. Ważny do: {niceDate(subscription?.endDate || todayDate())}</Badge>
        
          <p className="mt-2"> Zyskaj więcej funkcji w abonamencie premium. 
            <Button className="mt-2" onClick={handleProlong}>Kup abonament Premium</Button>
          </p>
        </>;
      }
    //   if (isAwaitingPaymentForPaidPlan) {
    //     return <><Badge color="failure">Niepłacony.</Badge><span> <Link to={`/order-plan?order=${subscription?.subscriptionPayment?.id}`}>Opłać tutaj.</Link></span></>;
    // }
    if (isOnPaidPlan && isPlanBeforeDeadline) {
      return <><Badge color="success">Aktywny.</Badge><span> Ważny do: {niceDate(subscription?.endDate || todayDate())}</span></>;      
    }
    return <><Badge color="success">Aktywny.</Badge><span> Ważny do: {niceDate(subscription?.endDate || todayDate())}</span></>;      

    }



      return (
        <div className="max-w-sm mx-auto">
          <Card>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900">
              Abonament: {subscription?.subscriptionPlan?.name || ''}
            </h5>
            <p className="text-gray-600">
             {setPlanInfoText()}
             </p>
          </Card>
        </div>
      );
    };