import Cookies from "js-cookie";
import { config } from "../config/config";

export const handleApiResponseError = (error: any): string => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data?.message || 'Wystąpił błąd. Spróbuj ponownie poźniej lub skontaktuj się z Obsługą Klienta.';
    } else if (error.request) {
      // The request was made but no response was received
      return 'Brak odpowiedzi serwera.Spróbuj ponownie poźniej lub skontaktuj się z Obsługą Klienta.';
    } else {
      // Something happened in setting up the request that triggered an Error
      return 'Wystąpił błąd. Spróbuj ponownie poźniej lub skontaktuj się z Obsługą Klienta.';
    }
  };

  type RequestResult<T> = {
    success: boolean,
    responseBody: T
  }

  export const request = async <ExpectedResponseType>(method: string, endpoint: string, options?: Record<string, string | object>): Promise<RequestResult<ExpectedResponseType>> => {
    try { 
      const endpointPrepared = endpoint.replace(/\{:userId\}/g, Cookies.get('userId') || '');
      console.log(endpoint, endpointPrepared);
      const authUserToken = Cookies.get('userToken');
  
      const response = await fetch(`${config.API_URL}${endpointPrepared}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          ...(authUserToken ? {'Authorization': `Bearer ${authUserToken}`} : {} ),
        },
        body: (options?.body ? JSON.stringify(options.body) : null),
      });
      
      // handle errors or no permission response
      if (!response.ok) {
        const { message, no_permission } = await response.json();
        // throw error if it comes from backend
        throw new AppError(message || no_permission, !!no_permission);
      }
      const result = await response.json();
      return {
        success: true,
        responseBody: result as ExpectedResponseType
      };
    } catch (error) {
      return {
        success: false,
        responseBody:  error as ExpectedResponseType
      }
    }
}


  export class AppError extends Error {
    public no_permission: boolean;

    constructor(message?: string, no_permission = false) {
      super(message || "Wystąpił błąd");
      this.no_permission = no_permission;
    }
    customFlashMessage(message: string){
      this.message = message;
    }
  }