import { Button, Navbar } from 'flowbite-react';
import LogoImage from '../../../assets/instafakt-logo-faktury-online.png';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';

export const SiteNavbar = () => {
    
    return (
        <div className="container mx-auto p-4">
                <Navbar fluid rounded>
                    <Navbar.Brand href="/">
                        <img src={LogoImage} className="mr-3 h-6 sm:h-9" alt="Faktury online" />
                        <span className="self-center whitespace-nowrap text-slate-600 font-semibold dark:text-white">Faktury online</span>
                    </Navbar.Brand>
                    <div className="flex md:order-2">
                        <Button href="/logowanie" color="light" outline={true} className='mx-4'>Zaloguj</Button>
                        <PrimaryButton linkTo="/rejestracja" text="Zarejestruj" />
                        <Navbar.Toggle />
                    </div>
                    <Navbar.Collapse>
                        <Navbar.Link href="/">Start</Navbar.Link>
                        <Navbar.Link href="/faktura-formularz">Stwórz fakturę</Navbar.Link>
                        <Navbar.Link href="/korzysci">Usługa</Navbar.Link>
                        <Navbar.Link href="/cennik">Cennik</Navbar.Link>
                        <Navbar.Link href="/kontakt">Kontakt</Navbar.Link>
                    </Navbar.Collapse>
                </Navbar >
                <hr className="h-px mb-8 mt-4 bg-gray-200 border-0" />
            </div>
    )
}