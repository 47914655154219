import { config } from "../../config/config";
import { AddPaymentResponse, PaymentForOrderRequestInput } from "./Order.types";

export const payForOrderRequest = async (input: PaymentForOrderRequestInput) => {
    try {
      console.log('input', input);
      const response = await fetch(`${config.API_URL}/payments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(input),
      });
  
      if (response.ok) {
        const paymentRes = await response.json() as AddPaymentResponse;
        return paymentRes.link;
      } else {
        // Payment creation failed
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
    } catch (error: unknown) {
      // Handle errors
      console.error('Error paying:', (error as any).message);
      throw error;
    }
  };