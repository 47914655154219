import { Invoice } from "../Invoice.types";
import { toCents } from "../../../utils/Numbers.util";

export const adaptInvoiceToAPI = (invoice: Invoice) => {
  return {
    ...invoice,
    seller_line_1: invoice.from?.line1,
    seller_line_2: invoice.from?.line2,
    seller_line_3: invoice.from?.line3,
    buyer_line_1: invoice.to?.line1,
    buyer_line_2: invoice.to?.line2,
    buyer_line_3: invoice.to?.line3,
    dueAt: invoice.dueAt,
    totalGross: toCents(invoice.totalGross),
    totalNet: toCents(invoice.totalNet),
    totalTax: toCents(invoice.totalTax),
    items: [...invoice.items.map(item => {
      return {
        ...item,
        quantity: item.quantity ? parseFloat(item.quantity) : 0,
        price: toCents(item.price),
        amountNet: toCents(item.amountNet),
        amountGross: toCents(item.amountGross),
        taxRate: toCents(item.taxRate),
        taxAmount: toCents(item.taxAmount),
      }
  })]
  }
};
