import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Invoice } from "../../invoice/Invoice.types";
import { request } from "../../../utils/Api.util";
import { useErrorBoundary } from "react-error-boundary";
import { adaptInvoiceAPIResponse } from "../../invoice/services/getInvoicesByUser.service";
import { InvoiceForm } from "../../invoice/Invoice";
import LoadingSpinner from "../../layout/Spinner/Spinner";

export const DashboardInvoiceEdit = () => {
    const [invoice, setInvoice] = useState<Invoice | null>(null);
    const {id} = useParams<{id: string}>();
    const { showBoundary } = useErrorBoundary();

    const fetchInvoice = async () => {
        const response = await request<unknown>('GET', `/invoices/${id}`).catch(error => showBoundary(error));
        const invoice = adaptInvoiceAPIResponse(response?.responseBody);
        setInvoice(invoice);
    }

    useEffect(() => {
        fetchInvoice();
    }, []);

    return (
        <>
            {/* todo: replace InvoceForm with some readonly view component */}
            {!invoice ? (<LoadingSpinner size='xl'/>) : (<InvoiceForm invoiceData={invoice} />)}
        </>
    )
}