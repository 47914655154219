import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Label,
  TextInput,
} from "flowbite-react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Link, useNavigate } from "react-router-dom";
import { setUser, UserRedux } from "../UserSlice";
import { AppError, request } from "../../../utils/Api.util";
import useQueryParam from "../../../utils/customHooks/useQueryParam";
import { validateEmail } from "../../../utils/validators/email.validator";
import { adaptInvoiceToAPI } from "../../invoice/services/adaptInvoiceData";
import { Invoice } from "../../invoice/Invoice.types";
import FlashMessage from "../../common/FlashMessage";
import { logUserIn } from "../services/RegisterService/Register.service";

export const RegisterPage = () => {
  const [userData, setUserData] = useState({ email: "", password: "" });
  const [flashMessage, setFlashMessage] = useState("");

  const [isTermsAgreeChecked, setTermsAgreeChecked] = useState(false);

  const [formFieldErrorTerms, setFormFieldErrorTerms] = useState("");
  const [formFieldErrorEmail, setFormFieldErrorEmail] = useState("");
  const [formFieldErrorPassword, setFormFieldErrorPassword] = useState("");
  const [chosenPlan, setChosenPlan] = useState(1);
  // const [generalError, setGeneralError] = useState<string | null>(null); // For general error handling

  const invoice = useAppSelector((state) => state.invoice);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryParamPlan = useQueryParam("plan");

  const handleTermsAgreeCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsAgreeChecked(e.target.checked);
    if (e.target.checked) {
      setFormFieldErrorTerms(""); // Hide error if the checkbox is checked
    }
  };

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormFieldErrorEmail(""); // Clear the error when the user starts typing
    setUserData({ ...userData, email: e.target.value });
  };

  const handlePasswordInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormFieldErrorPassword(""); // Clear the error when the user starts typing
    setUserData({ ...userData, password: e.target.value });
  };

  useEffect(() => {
    // Get the query parameter from the URL

    if (queryParamPlan !== null) {
      // Convert to a number
      const paramNumber = Number(queryParamPlan); // or use parseInt(queryParamPlan, 10) or parseFloat(queryParamPlan)
      if (!isNaN(paramNumber)) {
        setChosenPlan(paramNumber);
      } else {
        // Handle the case where the value is not a valid number
        //console.warn('Setting a chosen plan do default');
        setChosenPlan(1); // Or set a default value, like setParamValue(0)
      }
    }
  }, [queryParamPlan]);

  const handleRegisterButtonClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    // validate form fields
    let valid = true;
    const emailInvalidError = validateEmail(userData.email).error;
    if (emailInvalidError) {
      valid = false;
      setFormFieldErrorEmail(emailInvalidError);
    }

    // Password validation
    if (!userData.password) {
      setFormFieldErrorPassword("Hasło jest wymagane.");
      valid = false;
    } else if (userData.password.length < 6) {
      setFormFieldErrorPassword("Hasło musi mieć co najmniej 6 znaków.");
      valid = false;
    }

    // Checkbox validation
    if (!isTermsAgreeChecked) {
      setFormFieldErrorTerms("Zaznacz pole z akceptacją regulaminu.");
      valid = false;
    }

    if (!valid) {
      return;
    }
    const hasUnsavedInvoice = invoice?.number && !invoice?.id;

    const registerResponse = await request<UserRedux>("POST", "/register", {
      body: {
        user: userData,
        plan: chosenPlan,
      },
    });
    if (!registerResponse.success) {
      setFlashMessage(String(registerResponse.responseBody));
      return;
    }
    setFlashMessage("");

    const registeredUser = registerResponse?.responseBody;

    if (registeredUser?.token) {
      logUserIn(registeredUser.userId, registeredUser.token);

      // save user store
      dispatch(setUser(registeredUser));
      if (hasUnsavedInvoice) {
        await request<Invoice>("POST", `/users/{:userId}/invoices`, {
          body: { ...adaptInvoiceToAPI(invoice), isNewBuyer: true },
        });
      }

      // if (registeredUser?.subscription?.subscriptionPlanId != 1) {
      //     // need to pay for the paid subscription type
      //     navigate('/order-plan')

      // } else {
      //     navigate('/dashboard');
      // }

      navigate("/cennik");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="py-16 px-4 text-center bg-gradient-to-r">
        <h1 className="text-4xl font-bold mb-4 leading-tight tracking-tight text-gray-700">
          Rejestracja
        </h1>
        <p className="text-lg text-gray-500">
          Załóż konto w InstaFakt i zyskaj możliwość zapisywania faktur,
          drukowania, pobierania na dysk, wysyłania mailem i wiele więcej.
        </p>
      </div>

      {flashMessage && <FlashMessage error={new AppError(flashMessage)} />}

      <div className="flex justify-center space-x-36 py-8">
        <Card className="w-1/3">
          <div className="mb-1 mt-1 block">
            <Label htmlFor="email" value="Email:" />
          </div>
          <TextInput
            id="email"
            placeholder=""
            required
            shadow
            type="email"
            color="blue"
            value={userData.email}
            onChange={handleEmailInputChange}
          />
          {formFieldErrorEmail && (
            <Alert color="failure" className="mt-2">
              {formFieldErrorEmail}
            </Alert>
          )}

          <div className="mb-1 mt-4 block">
            <Label htmlFor="password" value="Hasło:" />
          </div>
          <TextInput
            id="password"
            required
            shadow
            type="password"
            color="blue"
            value={userData.password}
            onChange={handlePasswordInputChange}
          />
          {formFieldErrorPassword && (
            <Alert color="failure" className="mt-2">
              {formFieldErrorPassword}
            </Alert>
          )}

          <div className="flex gap-2 mt-7 mb-5">
            <Checkbox
              id="agree"
              className="cursor-pointer"
              onChange={handleTermsAgreeCheckboxChange}
            />

            <Label className="flex cursor-pointer" htmlFor="agree">
              <p className="text-xs">Akceptuję</p>
              <Link
                className="text-blue-600 hover:underline dark:text-blue-500 px-1"
                to="/regulamin"
                target="new"
              >
                <p className="text-xs"> regulamin i politykę prywatności</p>
              </Link>
            </Label>
          </div>
          {formFieldErrorTerms && (
            <Alert color="failure" className="mt-2">
              {formFieldErrorTerms}
            </Alert>
          )}

          <Button
            color="blue"
            fullSized={true}
            onClick={handleRegisterButtonClick}
          >
            Załóż konto
          </Button>
          <p className="text-sm text-center ont-light text-gray-500 dark:text-gray-400">
            Masz już konto?{" "}
            <a
              href="/logowanie"
              className="font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Zaloguj się
            </a>
            .
          </p>
        </Card>
      </div>
    </div>
  );
};
