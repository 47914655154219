import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Invoice } from "../../invoice/Invoice.types";
import { InvoiceView } from "../../invoice/InvoiceView";
import { request } from "../../../utils/Api.util";
import { useErrorBoundary } from "react-error-boundary";
import { adaptInvoiceAPIResponse } from "../../invoice/services/getInvoicesByUser.service";
import LoadingSpinner from "../../layout/Spinner/Spinner";

export const DashboardInvoiceView = () => {
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const { id } = useParams<{ id: string }>();
  const { showBoundary } = useErrorBoundary();
  const [isFetching, setIsFetching] = useState(true);

  const fetchInvoice = async () => {
    const response = await request<unknown>("GET", `/invoices/${id}`).catch(
      (error) => showBoundary(error)
    );
    setIsFetching(false);
    const invoice = adaptInvoiceAPIResponse(response?.responseBody);
    setInvoice(invoice);
  };

  useEffect(() => {
    fetchInvoice();
  }, []);

  return (
    <>
      {isFetching && <LoadingSpinner />}
      {!invoice ? [] : <InvoiceView invoiceData={invoice} />}
    </>
  );
};
