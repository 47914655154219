import html2canvas from "html2canvas"
import jsPDF from "jspdf"

// function for comparing if two objects are the same
export const isSame = (obj1: object, obj2: object) => {
    const obj1Keys = Object.keys(obj1)
    const obj2Keys = Object.keys(obj2)

    return obj1Keys.length === obj2Keys.length && obj1Keys.every((key) => obj1[key as keyof typeof obj1] === obj2[key as keyof typeof obj2])
}

// returns chunk of bigger object with keys specified
// commented out because unused
// export function createObjChunk(obj: any, keys: any) {
//     return keys.reduce((chunk: any, key: string) => {
//       if (key in obj) {
//         chunk[key] = obj[key];
//       }
//       return chunk; 
//     }, {});
//   }

export interface PreparePDFOptions {
    component: HTMLElement;
    fileName?: string; 
}
export const triggerPDFDownload = async (options: PreparePDFOptions) => {
    const elementsToHide = options.component.querySelectorAll('.hide-for-pdf');
    elementsToHide.forEach((el) => el.classList.add('hidden'));
    const canvas = await html2canvas(options.component);
  
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('portrait', 'pt', 'a4');
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

    pdf.save(options?.fileName || `dokument_instafakt_${Date.now()}.pdf`);
    elementsToHide.forEach((el) => el.classList.remove('hidden'));

}