import { useRef, useState } from "react";
import { niceDate } from "../../utils/Date.util";
import "./Invoice.styles.css";
import { Invoice, InvoiceItem } from "./Invoice.types";
import { Alert, Button, Card, Label, TextInput } from "flowbite-react";
import { useReactToPrint } from "react-to-print";
import { validateEmail } from "../../utils/validators/email.validator";
import { Link, useNavigate } from "react-router-dom";
import { triggerPDFDownload } from "../../utils/Common.util";
import { sendInvoiceByEMail } from "./services/sendInvoiceByEmail.service";
import { addThousandsSeparator } from "../../utils/Numbers.util";
import FlashMessage from "../common/FlashMessage";
import { AppError } from "../../utils/Api.util";
import LoadingSpinner from "../layout/Spinner/Spinner";
import { TbEdit } from "react-icons/tb";

export const InvoiceView = ({ invoiceData }: { invoiceData: Invoice }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [sendByEmailFormVisible, setSendByEmailFormVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [formFieldErrorEmail, setFormFieldErrorEmail] = useState("");
  const [generalError, setGeneralError] = useState<string | undefined>(
    undefined
  ); // For general error handling

  const handlePrintClick = useReactToPrint({
    onBeforeGetContent: () => setLoading(true),
    onAfterPrint: () => setLoading(false),
    content: () => componentRef.current ?? null,
  });

  const handleDownloadPDF = async () => {
    if (!componentRef.current) {
      return;
    }
    setLoading(true);
    await triggerPDFDownload({
      component: componentRef.current,
      fileName: `faktura_${invoiceData.number}_${invoiceData.id}.pdf`,
    });
    setLoading(false);
  };

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormFieldErrorEmail(""); // Clear the error when the user starts typing
    setEmail(e.target.value);
  };

  const handleSendByEmail = async () => {
    if (!componentRef.current) {
      // could not grab the html to convert to pdf
      return;
    }

    const emailInvalidError = validateEmail(email).error;
    if (emailInvalidError) {
      setFormFieldErrorEmail(emailInvalidError);
      return;
    }

    if (!invoiceData.id) {
      setGeneralError("Brak faktury do wyświetlenia.");
      return;
    }

    await sendInvoiceByEMail(
      email,
      componentRef.current.innerHTML,
      invoiceData.id
    );
  };

  return (
    <div ref={componentRef} className="container mx-auto p-4 bg-white ">
      {/* Spinner overlay */}
      {loading && <LoadingSpinner overlay />}
      <div className="container mx-auto py-6 px-4">
        {/* top menu icons */}
        <div className="flex justify-between">
          <h2 className="text-2xl font-bold mb-6 pb-2 tracking-wider uppercase">
            Faktura
          </h2>
          <div className="print:hidden hide-for-pdf">
            <div className="relative mr-4 inline-block ">
              <div
                className="text-gray-500 cursor-pointer w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-300 inline-flex items-center justify-center"
                onClick={handleDownloadPDF}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </div>
            </div>
            <div className="relative mr-4 inline-block">
              <div
                className="text-gray-500 cursor-pointer w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-300 inline-flex items-center justify-center"
                onClick={handlePrintClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-printer"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />
                  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />
                  <rect x="7" y="13" width="10" height="8" rx="2" />
                </svg>
              </div>
            </div>
            <div className="relative mr-4 inline-block">
              <div
                className="text-gray-500 cursor-pointer w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-300 inline-flex items-center justify-center"
                onClick={() =>
                  setSendByEmailFormVisible(!sendByEmailFormVisible)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="0" y="0" width="24" height="24" stroke="none"></rect>

                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                  />
                </svg>
              </div>
            </div>

            <div className="relative mr-4 inline-block">
              <div
                className="text-gray-500 cursor-pointer w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-300 inline-flex items-center justify-center"
                onClick={() => {
                  navigate(`/dashboard/invoices/${invoiceData.id}/edit`);
                }}
              >
                <TbEdit size={24} title="Edytuj" to={"/"} />
              </div>
            </div>
          </div>
        </div>

        {/* send by email fo  rm */}
        {sendByEmailFormVisible ? (
          <div
            className="flex mb-8 justify-center print:hidden"
            data-html2canvas-ignore="true"
          >
            <Card className="w-1/3">
              <div className="mb-1 mt-1 block">
                <Label htmlFor="email" value="Wyślij fakturę na email:" />
              </div>
              <TextInput
                id="email"
                placeholder="przykładowy@email.pl"
                required
                shadow
                type="email"
                color="blue"
                value={email}
                onChange={handleEmailInputChange}
              />
              {formFieldErrorEmail && (
                <Alert color="failure" className="mt-2">
                  {formFieldErrorEmail}
                </Alert>
              )}

              <Button color="blue" onClick={handleSendByEmail}>
                Wyślij fakturę
              </Button>
              <Link
                className="text-blue-600 text-xs hover:underline dark:text-blue-500 px-1"
                to="#"
                onClick={() => setSendByEmailFormVisible(false)}
              >
                Anuluj
              </Link>
            </Card>
          </div>
        ) : (
          ""
        )}
        {generalError && <FlashMessage error={new AppError(generalError)} />}

        {/* row with invoice number, dates */}
        <div className="flex mb-8 justify-between">
          <div className="w-2/4">
            <div className="mb-2 md:mb-1 md:flex items-center">
              <label className="w-30 text-gray-800 block font-bold text-sm uppercase tracking-wide">
                Numer faktury
              </label>
              <span className="mr-2 inline-block md:block">:</span>
              <span>{invoiceData.number}</span>
            </div>
          </div>
          <div className="w-2/4 text-right">
            <div className="mb-2 md:mb-1 text-right">
              <span className="text-gray-800 block uppercase text-sm tracking-wide text-right">
                <span className="font-bold">Data wystawienia:</span>{" "}
                {niceDate(invoiceData.issuedAt)}
              </span>
            </div>

            <div className="mb-2 md:mb-1 text-right">
              <span className="text-gray-800 block uppercase text-sm tracking-wide text-right">
                <span className="font-bold">Data wykonania / sprzedaży:</span>{" "}
                {niceDate(invoiceData.deliveredAt)}
              </span>
            </div>
          </div>
        </div>

        {/* sender, recipient */}
        <div className="flex flex-wrap justify-between mb-8">
          <div className="w-full md:w-1/3 mb-2 md:mb-0 print:w-1/2">
            <div className="text-gray-800 block mb-2 font-bold text-sm uppercase tracking-wide">
              Sprzedawca:
            </div>
            <div>{invoiceData.from.line1} </div>
            <div>{invoiceData.from.line2} </div>
            <div>{invoiceData.from.line3} </div>
          </div>

          <div className="w-full md:w-1/3 print:w-1/2">
            <div className="text-gray-800 block mb-2 font-bold text-sm uppercase tracking-wide">
              Nabywca:
            </div>
            <div>{invoiceData.to.line1} </div>
            <div>{invoiceData.to.line2} </div>
            <div>{invoiceData.to.line3} </div>
          </div>
        </div>

        {/* items header */}
        <div className="flex -mx-1 border-b py-2 items-start">
          <div className="flex-1 px-1 w-32 ">
            <p className="text-gray-800 uppercase tracking-wide text-sm font-bold">
              Nazwa
            </p>
          </div>

          <div className="px-1 w-32">
            <p className="text-gray-800 uppercase tracking-wide text-sm font-bold">
              Cena netto
            </p>
          </div>

          <div className="px-1 w-20">
            <p className="leading-none">
              <span className="block uppercase tracking-wide text-sm font-bold text-gray-800">
                Ilość
              </span>
            </p>
          </div>

          <div className="px-1 w-32">
            <p className="leading-none">
              <span className="block uppercase tracking-wide text-sm font-bold text-gray-800">
                Typ
              </span>
            </p>
          </div>

          <div className="px-1 w-32">
            <p className="leading-none">
              <span className="block uppercase tracking-wide text-sm font-bold text-gray-800">
                Kwota netto
              </span>
            </p>
          </div>

          <div className="px-1 w-32">
            <p className="leading-none">
              <span className="block uppercase tracking-wide text-sm font-bold text-gray-800">
                Stawka VAT
              </span>
              <span className="font-medium text-xs text-gray-500">(w %)</span>
            </p>
          </div>

          <div className="px-1 w-20">
            <p className="leading-none">
              <span className="block uppercase tracking-wide text-sm font-bold text-gray-800">
                VAT
              </span>
            </p>
          </div>

          <div className="px-1 w-32">
            <p className="leading-none">
              <span className="block uppercase tracking-wide text-sm font-bold text-gray-800">
                Kwota brutto
              </span>
            </p>
          </div>
        </div>

        {/* items */}
        <div>
          {/* Invoice items */}
          {invoiceData.items.map((item: InvoiceItem, index) => (
            <div key={index} className="flex -mx-1 py-2">
              <div className="flex-1 px-1 w-32">
                <span>{item.description}</span>
              </div>
              <div className="px-1 w-32 text-center">
                <span>{item.price}</span>
              </div>
              <div className="px-1 w-20 text-center">
                <span>{item.quantity}</span>
              </div>

              <div className="px-1 w-32">
                <span>{item.type}</span>
              </div>

              <div className="px-1 my-auto w-32 text-center">
                <span>{item.amountNet}</span>
              </div>

              <div className="px-1 w-20 text-center">
                <span>{item.taxRate}</span>
              </div>

              <div className="px-1 my-auto w-32 text-center">
                <span>{item.taxAmount}</span>
              </div>

              <div className="px-1 my-auto w-32 text-center">
                <span>{item.amountGross}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="py-2 ml-auto mt-5 w-full sm:w-2/4 lg:w-1/4">
          <div className="flex justify-between mb-3">
            <div className="text-gray-800 text-right flex-1">Suma netto</div>
            <div className="text-right w-40">
              <div className="text-gray-800 font-medium">
                {addThousandsSeparator(invoiceData.totalNet)}
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-4">
            <div className="text-sm text-gray-600 text-right flex-1">
              Suma VAT
            </div>
            <div className="text-right w-40">
              <div className="text-sm text-gray-600">
                {addThousandsSeparator(invoiceData.totalTax)}
              </div>
            </div>
          </div>

          <div className="py-2 border-t border-b">
            <div className="flex justify-between">
              <div className="text-xl text-gray-600 text-right flex-1">
                Razem do zapłaty
              </div>
              <div className="text-right w-40">
                <div className="text-xl text-gray-800 font-bold">
                  {addThousandsSeparator(invoiceData.totalGross)} PLN
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Options at the bottom  --> */}

        <div className="flex max-w-md flex-col gap-4">
          {invoiceData.dueAt ? (
            <div>
              <div className="mb-2 block">
                <Label htmlFor="dueDate" value="Termin zapłaty" />
              </div>
              <span>{niceDate(invoiceData.dueAt)}</span>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="w-full py-10 text-center">
          {/* <Button color="blue" className="font-bold" onClick={() => handleSaveButton()}>Zapisz fakturę</Button> */}
        </div>

        {/* <RegisterModal show={registerModalState} onClose={() => setRegisterModalState(false)} /> */}
      </div>
    </div>
  );
};
