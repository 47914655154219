import { InvoiceForm } from "../invoice/Invoice";
import HeroImage from "./../../assets/program-do-faktur-hero1.jpg";
import { Button } from "flowbite-react";
const LandingPage = () => {
  const scrollToForm = () => {
    const formElement = document.getElementById("invoice-form");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 pb-8">
      {/* Marketing Section */}
      <section className="text-grey-800 py-16 px-6">
        <div className="max-w-screen-xl mx-auto grid md:grid-cols-2 gap-16 items-center">
          <div>
            <h1 className="text-4xl font-bold mb-4">
              Stwórz fakturę online w kilka minut!
            </h1>
            <p className="text-lg mb-6">
              Nasze narzędzie pomoże Ci zaoszczędzić czas i zapewni
              profesjonalne faktury. Proste, szybkie i intuicyjne!
            </p>
            <ul className="list-disc list-inside mb-10">
              <li className="mb-3">Twórz faktury w kilka minut</li>
              <li className="mb-3">
                Profesjonalne szablony zgodne z przepisami
              </li>
              <li className="mb-3">
                Elastyczność w zakresie walut, podatków i innych
              </li>
            </ul>
            <Button
              size="xl"
              color="blue"
              className="font-bold"
              onClick={scrollToForm}
            >
              Stwórz fakturę
            </Button>
          </div>
          {/* Image or Icon */}
          <div className="flex justify-center md:justify-end px-12">
            <img
              src={HeroImage}
              alt="program do faktur online"
              className="rounded-lg shadow-lg shadow-grey-50"
            />
          </div>
        </div>
      </section>

      {/* Invoice Form */}
      <div id="invoice-form">
        <InvoiceForm />
      </div>
    </div>
  );
};

export default LandingPage;
