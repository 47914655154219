export type ValidationResult = {
    error: string | false;
};

export const validatePassword = (password ?: string): ValidationResult => {
    if (!password) {
        return { error: 'Hasło jest wymagane.'};
    } else if (password.length < 6) {
        return { error: 'Hasło musi mieć co najmniej 6 znaków.' };
    }
    return { error: false };
}