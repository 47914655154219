import { redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { request } from "../../../utils/Api.util";
import { User } from "./RegisterService/Register.types";
import { store } from "../../../store";
import { setUser } from "../UserSlice";

export const requireAuth = async () => {
  // const userToken = localStorage.getItem("userToken");
  const userToken = Cookies.get('userToken');

  const state = store.getState();

  const {user: userInStore } = state;

  if (!userToken) {
    // Redirect to login if token doesn't exist
    return redirect("/logowanie");
  }

  try {
    console.log('userInStore', userInStore)
    
    if (!userInStore.userId) {
      
      const fetchedUserData = await request<User>('GET', '/users')
      console.log('fetchedUserData', fetchedUserData)
      
      store.dispatch(setUser(fetchedUserData?.responseBody));
    }
    console.log('returning ok from requireAuth')

    return true;
  } catch (error) {
    return redirect('/logowanie');
  }
}
