import { Button, Card } from "flowbite-react";
import { HeaderSection } from "../layout/Public/HeaderSection/HeaderSection";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { config } from "../../config/config";
import LoadingSpinner from "../layout/Spinner/Spinner";
import { ImSad } from "react-icons/im";
import { TbThumbUp } from "react-icons/tb";

export function OrderComplete() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const [headingText, setHeadingText] = useState("Płatność w toku");
  const [statusIcon, setStatusIcon] = useState(<LoadingSpinner size="lg" />);
  const [statusTitleText, setStatusTitleText] = useState(
    "Jeszcze chwileczkę..."
  );
  const [statusDescription, setStatusDescription] = useState(
    "Oczekujemy na potwierdzenie od naszego partnera płatności online."
  );
  const pid = searchParams.get("pid"); // Get the 'pid' query parameter

  useEffect(() => {
    if (!pid) {
      return;
    }
    // Open an EventSource connection to listen for payment status updates
    const eventSource = new EventSource(
      `${config.API_URL}/payments/${pid}/event`
    );

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setPaymentStatus(data.status);

      if (
        data.status === "paid" ||
        data.status === "timeout" ||
        data.status === "error"
      ) {
        eventSource.close(); // Close the connection when status is resolved
      }
    };

    eventSource.onerror = (error) => {
      console.error("SSE connection error:", error);
      eventSource.close();
    };

    return () => {
      eventSource.close(); // Clean up the connection when the component unmounts
    };
  }, [pid]);

  useEffect(() => {
    if (paymentStatus == "paid") {
      setHeadingText("Płatność zakończona");
      setStatusTitleText("Dziękujemy");
      setStatusDescription(
        "Płatność zaksięgowana. Twój abonament jest już aktywny"
      );
      setStatusIcon(<TbThumbUp size={50} />);
    } else if (["timeout", "error"].includes(paymentStatus)) {
      setHeadingText("Problem z płatnością");
      setStatusTitleText("Przepraszamy");
      setStatusDescription(
        "Nie otrzymaliśmy potwierdzenia Twojej płatności od naszego partnera. Czasem trwa to dłużej niż oczekiwano. Sprawdź za kilka chwil czy Twój abonament jest aktywny lub dokonaj zakupu ponownie."
      );
      setStatusIcon(<ImSad size={50} />);
    }
    return;
  }, [paymentStatus]);

  return (
    <>
      <HeaderSection heading={headingText} />
      <div className="flex items-center justify-center">
        <Card className="max-w-md text-center">
          <div className="text-green-600 w-20 h-20 mx-auto mb-4">
            {statusIcon}
          </div>
          <h3 className="text-2xl font-bold text-gray-800">
            {statusTitleText}
          </h3>
          <p className="text-gray-600">{statusDescription}</p>
          {paymentStatus == "paid" && (
            <div className="mt-6 space-y-2">
              <Button
                gradientMonochrome="info"
                fullSized={true}
                onClick={() => navigate("/dashboard")}
              >
                Przejdź do panelu
              </Button>
              <Button
                outline={true}
                gradientMonochrome="success"
                fullSized={true}
                onClick={() => navigate("/dashboard/account")}
              >
                Zarządzaj abonamentem i ustawieniami
              </Button>
            </div>
          )}
        </Card>
      </div>
    </>
  );
}
