import { useEffect } from "react";

interface WindowWIthGtag extends Window {
    gtag: (googleType: string, goolgeEventName?: string, options?: unknown) => void
}

export const usePageTracking = () => {
    const location = window.location;

    useEffect(() => {
        (window as unknown as WindowWIthGtag).gtag("event", "page_view", {
            page_path: location.pathname + location.search + location.hash,
            page_search: location.search,
            page_hash: location.hash,
        });
    }, [location]);
};